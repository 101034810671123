import {
  DashboardOutlined, EditOutlined, ExceptionOutlined, FileAddOutlined, FundOutlined,
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const dashBoardNavTree = [
  // {
  //   key: 'home',
  //   path: `${APP_PREFIX_PATH}/home`,
  //   title: 'home',
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  {
    key: 'dashboard-home',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: '대시보드',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: '대시보드',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
        // isDev: true,
      },
      {
        key: 'kwonrimap-dashboard',
        path: `${APP_PREFIX_PATH}/kwonrimap-dashboard`,
        title: '권리맵 대시보드',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
        // isDev: true,
      }
    ],
  },
  {
    key: 'report-home',
    path: `${APP_PREFIX_PATH}/report`,
    title: '리포트',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'report-add',
        path: `${APP_PREFIX_PATH}/report/add`,
        title: '새 리포트 등록',
        icon: EditOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'report-list',
        path: `${APP_PREFIX_PATH}/report/list`,
        title: '리포트 목록',
        icon: FundOutlined,
        isDev: false,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'report-application-list',
        path: `${APP_PREFIX_PATH}/report/application`,
        title: '리포트 신청서 목록',
        icon: ExceptionOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'dev',
    path: `${APP_PREFIX_PATH}/dev`,
    title: '리포트 관리자',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dev-1',
        path: `${APP_PREFIX_PATH}/dev/1`,
        title: '산출 모델 관리',
        isDev: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dev-2',
        path: `${APP_PREFIX_PATH}/settings/overall`,
        title: '총평 자동화 관리',
        // isDev: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dev-3-list',
        path: `${APP_PREFIX_PATH}/dev/3`,
        title: '유명 브랜드 관리',
        isDev: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dev-4-list',
        path: `${APP_PREFIX_PATH}/dev/4`,
        title: '리포트 템플릿 관리',
        isDev: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dev-5-list',
        path: `${APP_PREFIX_PATH}/dev/5`,
        title: '문자 템플릿 관리',
        isDev: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      // {
      //   key: 'dev-6-list',
      //   path: `${APP_PREFIX_PATH}/request-sample-list`,
      //   title: '리포트 샘플 요청 내역',
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: []
      // },
    ]
  }]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
